<template>
	<div class="">
		<search-item-vue @searchEvent="searchClick"></search-item-vue>
		<div class="list-main">
			<el-table :data="tableData" v-loading="isLoad" style="width: 100%" :header-cell-style="{height:'48px;',background:'#F6F7F9',color:'#6C7880'}" >
				<el-table-column prop="order_no" label="订单编号" width="135"></el-table-column>
				<el-table-column prop="company.company" label="下单企业" width="210"></el-table-column>
				<el-table-column prop="orderProduct" label="产品信息" width="330">
					<template slot-scope="scope">
						<span class="flex flex-col-center">
							<img class="product-img" v-for="(item, index) in scope.row.orderProduct.product_img" :key="index" :src="item" alt="" />
							<span>{{scope.row.orderProduct.name}}</span>							
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="dict_name" label="价格/收费方式" width="200">
					<template slot-scope="scope">
						<span style="color: #FD5451;">{{scope.row.orderProduct.dictionary.dict_name}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="num" label="订购时长" width="100">
					<template slot-scope="scope">
						<span>{{scope.row.orderProduct.num}}个月</span>
					</template>
				</el-table-column>
				<el-table-column prop="total_amount" label="订单金额" width="125">
					<template slot-scope="scope">
						<span style="color: #FD5451;">￥{{scope.row.total_amount}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="create_time" label="下单时间" width="175"></el-table-column>
				<el-table-column prop="order_status" label="订单状态" width="120">
					<template slot-scope="scope">
						<span :style="[getState(scope.row.order_status)]">{{scope.row.order_status_text}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="orderDetailsClick(scope.row.id)">订单详情</el-button>
						<el-button type="text" size="small" v-if="scope.row.order_status=='1'" @click="enterOrder(scope.row.id)">立即确认</el-button>
						<el-button type="text" size="small" v-if="scope.row.order_status=='2'" @click="handleClick(scope.row)">立即办理</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="page-btn-content flex flex-col-center flex-row-end">
			 <el-pagination
			      @current-change="currentChange"
				  background
				  :pager-count="11"
			      :page-size="8"
			      layout="total, prev, pager, next"
			      :total="pageAllSize">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import searchItemVue from '../components/search-item.vue';
	export default {
		components: {
			searchItemVue
		},
		data(){
			return {
				tableData: [],
				pageIndex: 1,
				isLoad: false,
				subData: {
					page: 1,
					limit: 8,
					order_status: '0',
					product_name: '',
					order_no: '',
					start_time: '',
					end_time: ''
				},//提交数据 
				pageAllSize: 0,
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData(){
				this.isLoad = true;
				this.subData.page = this.pageIndex;
				this.$api.orderPageList(this.subData).then(res=>{
					this.pageAllSize = res.data.count;
					this.tableData = res.data.data;
					this.isLoad = false;
				}).catch(err=>{
					this.isLoad = false;
				})
			},
			//查询
			searchClick(e){
				this.subData.product_name = e.name;
				this.subData.order_no = e.num;
				this.subData.start_time = e.start;
				this.subData.end_time = e.end;
				this.pageIndex = 1;
				this.initData();
			},
			//翻页 
			currentChange(val) {
				this.pageIndex = val;
				this.initData();
			},
			//确认订单
			enterOrder(e){
				this.$confirm('是否确认办理该订单？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消',type: 'warning'}).then(() => {
					this.$api.orderOrder_confirm(e).then(res=>{
				        this.$message({type: 'success', message: '操作成功!'});
						this.initData();
					})
				})
			},
			//立即办理
			handleClick(e){
				this.$confirm('是否立即办理?', '提示', { confirmButtonText: '是', cancelButtonText: '否',type: 'warning'}).then(() => {
					this.$api.order_handle({id: e.id}).then(res=>{
				        this.$message({type: 'success', message: '操作成功!'});
						this.initData();
					})
				})
			},
			//订单详情
			orderDetailsClick(e){
				this.$router.push({path: '/orderDetails', query: {data: e}});
			},
			//获取状态
			getState(num){
				let str = {color: '#04CD83'};
				switch (num){
					case 1:
						str.color = '#04CD83';
						break;
					case 2:
						str.color = '#04CD83';
						break;
					case 3:
						str.color = '#04CD83';
						break;
					case 4:
						str.color = '#242D33';
						break;
					case 5:
						str.color = '#242D33';
						break;
					default:
						break;
				}
				return str;
			}
			
		},
	}
</script>

<style lang="scss" scoped>
	.list-main{padding: 0 30px;}
	.page-btn-content{padding: 25px 30px 0 0;}
	.product-img{width: 70px;height: 70px;background: #CEDAE0;border-radius: 6px;margin-right: 10px;}
	
</style>